<template lang="">
    <div>
        <p
            class="font-weight-bold text-center font-size-h5 font-size-h5-lg ml-8 mr-8 mb-14"
            style="color: #063b5c;"
          >
            SISTEMA DE GESTIÓN, EJECUCIÓN Y ANÁLISIS DE INFORMACIÓN DEL SISTEMA
            NACIONAL DE COOPERACIÓN PARA EL DESARROLLO
          </p>

          <div class="row pt-4" style="flex: none !important;">
            <div class="col col-md-12">
              <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-4">
                <a href="https://www.segeplan.gob.gt" target="_blank" class="text-center mb-10">
                  <img
                    src="media/logos/logo-letter-1.png"
                    class="max-h-120px logo-sege"
                    alt=""
                  />
                </a>
                <!-- <p
                class="font-weight-bold text-center font-size-h6 font-size-h5-lg ml-8 mr-8 mb-14"
                style="color: #063b5c;"
              >
                SISTEMA DE GESTIÓN, EJECUCIÓN Y ANÁLISIS DE INFORMACIÓN DEL SISTEMA
                NACIONAL DE COOPERACIÓN PARA EL DESARROLLO
              </p> -->
              </div>
            </div>
          </div>


          <table>
	
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Widget</th>
                <th>Wingding</th>
                <th>Whatsit</th>
                <th>Whirlygig</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                <td>CompanyA</td>
                <td>5</td>
                <td>8</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>CompanyB</td>
                <td>2</td>
                <td>0</td>
                <td>3</td>
                <td>4</td>
              </tr>
              <tr>
                <td>CompanyC</td>
                <td>0</td>
                <td>7</td>
                <td>2</td>
                <td>0</td>
              </tr>
              <tr>
                <td>CompanyD</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>3</td>
              </tr>
              <tr>
                <td>CompanyE</td>
                <td>3</td>
                <td>0</td>
                <td>3</td>
                <td>0</td>
              </tr>
              <tr>
                <td><strong>Grand Total</strong></td>
                <td>10</td>
                <td>15</td>
                <td>8</td>
                <td>7</td>
              </tr>
          </tbody>
          <caption>Products purchased last month</caption>
        </table>>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>